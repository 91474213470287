<template>
  <div class="wrapper">
    <div class="page-wrap">
      <v-row class="logos flex-column">
        <div class="title">
          <img
            width="170px"
            src="../../../assets/images/Majaal_blue.png"
            alt="Majaal"
          />
        </div>
        <div class="title">
          <img
            width="130px"
            src="../../../assets/images/majaal-slogan.png"
            alt="Majaal"
          />
        </div>
      </v-row>
      <div class="subtitle">
        <h1>عرض سعر</h1>
      </div>
      <v-row style="width: 95%">
        <v-spacer></v-spacer>
        <div style="text-align: right">
          <div class="col-12">{{ userName }} :العميل</div>
          <div class="col-12">{{ projectName }} :المشروع</div>
          <div class="col-12">{{ date }} :التاريخ</div>
          <div class="col-12">{{ reportData.odooDisplayId }} :رقم</div>
          <div class="col-12">{{ comment }} :ملاحظات</div>
        </div>
      </v-row>
      <div
        v-for="(table, i) in tables"
        :key="i"
        :class="`table-container ${table.length > 3 ? 'html2pdf__page-break' : ''}`"
        :style="`margin-top: ${i != 0 ? '80' : '0'}px`"
      >
        <table class="table">
          <colgroup>
            <col width="22.5%" />
            <col width="22.5%" />
            <col width="20%" />
            <col width="35%" />
          </colgroup>
          <thead class="table-header">
            <tr>
              <th class="u-table-cell">الإجمالي</th>
              <th class="u-table-cell">السعر</th>
              <th class="u-table-cell">الكمية</th>
              <th class="u-table-cell">الصنف</th>
            </tr>
          </thead>
          <tbody class="u-table-alt-palette-1-light-3 u-table-body">
            <tr v-for="(item, i) in table" :key="i">
              <td>
                {{ item.baseItem.price * item.quantity }}
                د.ل
              </td>
              <td>
                <span> د.ل </span>
                <span> /{{ item.uom.name }} </span>
                <span>
                  {{ item.baseItem.price }}
                </span>
              </td>
              <td>
                {{ item.quantity }}
              </td>
              <td>
                {{ item.baseItem.displayName }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="total">
        <strong>د.ل </strong>
        <strong>{{ total }}</strong>
        <h1>:الإجمالي</h1>
      </div>
    </div>
    <div v-if="total > 4" class="html2pdf__page-break"></div>
    <v-row style="padding-top: 40px; width: 95%; flex-direction: row-reverse">
      <div style="width: 65%">
        <h1 style="text-align: right">-:ملاحظات</h1>
        <ul>
          <li style="text-align: right; font-size: 15px">
            يتم حجز الكميات واستصدار أمر البيع عن طريق التواصل مع أحد الفروع عبر
            الأرقام المدونة أدناه أو إدارة تجربة العملاء على الرقم 0917733311
          </li>
          <li style="padding-top: 40px; text-align: right; font-size: 15px">
            صلاحية العرض إسبوع من تاريخ الإصدار
          </li>
        </ul>
      </div>
    </v-row>

    <div style="display: flex;margin-top:80px;">
      <img
        style="margin: auto"
        width="80%"
        src="../../../assets/images/majaal_contact.png"
        alt="Majaal"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: ["items", "total", "reportData", "extraInfo"],
  computed: {
    userName() {
      return `${this.reportData.user?.firstName} ${this.reportData.user?.lastName}`;
    },
    date() {
      return this.reportData.createdAt?.slice(0, 10);
    },
    projectName() {
      return this.extraInfo.project_name;
    },
    comment() {
      return this.extraInfo.comment;
    },
    tables() {
      var chunk,
        rest = this.items.length,
        start = 0,
        end = 10;

      var temp = [];

      for (var i = 0; i < this.items.length; i += 10) {
        rest -= 10;

        chunk = this.items.slice(start, end);
        temp.push(chunk);

        start = end;
        if (rest >= 10) end += 10;
        else end = this.items.length;
      }
      return temp;
    },
  },
  components: {},
  methods: {},
};
</script>

<style scoped>
.wrapper {
  padding-bottom: 100px;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
}
.logos {
  padding-right: 40px;
  padding-left: 40px;
  padding-top: 30px;
}
.title {
  display: flex;
  justify-content: center;
  font-size: 1.5rem;
  color: black;
  margin: auto;
}
.subtitle {
  padding-top: 30px;
  display: flex;
  justify-content: center;
  font-size: 1.3rem;
}
.total {
  padding-top: 70px;
  display: flex;
  justify-content: center;
  align-content: baseline;
  align-items: baseline;
  font-size: 1.2rem;
  color: black;
}

.table-container {
  padding-top: 20px;
  display: flex;
  justify-content: center;
  width: 90%;
  margin: auto;
}
.table {
  margin: auto;
  width: 100%;
}
.table-header {
  background: #898989;
}
thead > tr > th {
  color: white !important;
}
.colored {
  background: #e0e5eb;
}

* {
  margin: 0;
  direction: ltr;
}
html,
body {
  height: 100%;
}

.site-footer {
  position: absolute;
  bottom: -100;
  right: 0;
  /* margin-left: 500px;*/
  float: right;
}
.u-table-cell,
td {
  text-align: right !important;
}
</style>